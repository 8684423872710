import React, { useEffect, useState } from 'react';
import * as Styled from './homeBannerStyle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import IconOpenAccount from '../../../images/icon-open-account.png';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import useContentfulAssets from '../../../hooks/useContentfulAssets';
import { SECTIONS } from '../../../constants';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';

const HomeBanner = ({ homeBanner, delay }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const homeBannerData = homeBanner?.listSection;

  const mobileData = homeBannerData?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.TEXT_WITH_PATH_FOR_MOBILE;
  })[0];
  const mobileCardlockData = homeBannerData?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.LINK_WITH_PDF;
  })[0];

  const mobileDataStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        node?.content?.find((node) =>
          node?.nodeType?.includes('asset-hyperlink')
        ) ? (
          <div>{children}</div>
        ) : (
          <p className="m-0 d-inline">{children}</p>
        ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.HomeBannerBoxAccount
              href={asset?.node?.file?.url}
              target="_blank"
              $scrollPosition={scrollPosition}
            >
              <Styled.HomeBannerBoxAccountInner>
                <Styled.HomeBannerBoxAccountInnerImage src={IconOpenAccount} />
                {mobileCardlockData?.header}
              </Styled.HomeBannerBoxAccountInner>
            </Styled.HomeBannerBoxAccount>
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.HomeBannerBox>
      <Styled.HomeBannerBoxExplore
        $backgroundImage={homeBanner.backgroundImages[0]?.file?.url}
        href={isExternalUrlHref(homeBanner.textWithLink1?.path)? homeBanner.textWithLink1?.path :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + homeBanner.textWithLink1?.path)}
        target={
          isExternalUrl(homeBanner.textWithLink1?.path) ? '_blank' : '_self'
        }
      >
        <Styled.HomeBannerBoxExploreInner>
          <Styled.HomeBannerBoxExploreInnerHeader
            data-aos="fade-up"
            data-aos-delay={delay}
            data-aos-duration="1000"
            data-aos-once="true"
          >
            {homeBanner.label}
          </Styled.HomeBannerBoxExploreInnerHeader>
          <Styled.HomeBannerBoxExploreInnerParagraph
            data-aos="fade-up"
            data-aos-delay={delay}
            data-aos-duration="1000"
            data-aos-once="true"
          >
            {homeBanner.textWithLink1.text}
          </Styled.HomeBannerBoxExploreInnerParagraph>
        </Styled.HomeBannerBoxExploreInner>
      </Styled.HomeBannerBoxExplore>
      {homeBanner?.textWithLink2 && (
        <Styled.HomeBannerBoxAccount
          href={isExternalUrlHref(homeBanner?.textWithLink2?.path)?homeBanner?.textWithLink2?.path :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + homeBanner?.textWithLink2?.path)}
          target={
            isExternalUrl(homeBanner?.textWithLink2?.path) ? '_blank' : '_self'
          }
          $scrollPosition={scrollPosition}
        >
          <Styled.HomeBannerBoxAccountInner>
            <Styled.HomeBannerBoxAccountInnerImage
              src={homeBanner?.textWithLink2?.image?.file?.url}
              alt={homeBanner?.textWithLink2?.image?.title}
            />
            {homeBanner?.textWithLink2?.richText?.raw &&
              documentToReactComponents(
                JSON.parse(homeBanner?.textWithLink2?.richText?.raw),
                mobileDataStyle
              )}
          </Styled.HomeBannerBoxAccountInner>
        </Styled.HomeBannerBoxAccount>
      )}
      {mobileCardlockData?.contentDetails?.raw &&
        documentToReactComponents(
          JSON.parse(mobileCardlockData?.contentDetails?.raw),
          mobileDataStyle
        )}
    </Styled.HomeBannerBox>
  );
};

export default HomeBanner;
